import moment from "moment";

export function formatNumberWithDots(number) {
  return (number || 0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function calculateTimeDifference(startTime, endTime) {
  const start = new Date(startTime);
  const end = new Date(endTime);

  // Tính sự khác biệt thời gian (milliseconds)
  const diffMs = end.getTime() - start.getTime();

  // Chuyển đổi sự khác biệt thời gian từ milliseconds thành phút và giờ
  const diffMins = Math.floor(diffMs / 1000 / 60);
  const hours = Math.floor(diffMins / 60);
  const minutes = diffMins % 60;

  // Tạo chuỗi kết quả
  return `${hours}g ${minutes}p`;
}

export function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}g ${remainingMinutes}p`;
}

export function checkTimeRange(specificTime) {
  const now = moment();
  const specificMoment = moment(specificTime);

  const diffInHours = specificMoment.diff(now, "hours");
  const diffInDays = specificMoment.diff(now, "days");

  if (diffInHours >= 0 && diffInHours <= 1) {
    return "vmb_1"; //Vé sát 0-1 giờ
  } else if (diffInHours > 1 && diffInHours <= 2) {
    return "vmb_2"; //Vé sát 2h
  } else if (diffInHours > 2 && diffInDays <= 7) {
    return "vmb_3"; //Vé 2h-7 ngày
  } else if (diffInDays > 7) {
    return "vmb_4"; //Trên 7 ngày
  } else {
    return "Ngoài phạm vi";
  }
}

export const getStatusDiv = (status) => {
  // Define status mappings
  const statusMap = {
    1: { text: "Đặt vé", bgColor: "bg-green-500" },
    2: { text: "Giữ chỗ", bgColor: "bg-yellow-400" },
    3: { text: "Xuất vé", bgColor: "bg-blue-500" },
    "-2": { text: "Lỗi đặt vé", bgColor: "bg-red-500" },
    "-3": { text: "Lỗi xuất vé", bgColor: "bg-red-500" },
  };

  // Find the appropriate status from the map
  const { text, bgColor } = statusMap[status] || {};

  // Return the div only if the status is valid
  if (text && bgColor) {
    return (
      <div
        className={`text-white ${bgColor} rounded-[20px] px-4 py-1 w-fit text-[10px]`}
      >
        {text}
      </div>
    );
  }

  // Return a fallback if the status is invalid
  return (
    <div className="text-white bg-gray-400 rounded-[20px] px-4 py-1 w-fit text-[10px]">
      Không xác định
    </div>
  );
};
