import React, { useState, useEffect } from "react";
import BgSuccess from "../../images/bg-success.svg";
import { Button, notification } from "antd";
import _ from "lodash";
import $axios from "../../services/axios";
import { useLocation, useHistory } from "react-router-dom";

const Step6 = ({ setLoading, loading }) => {
  const history = useHistory();
  const location = useLocation();
  const [statusPayment, setStatusPayment] = useState(false);
  const bookingInfo = JSON.parse(sessionStorage.getItem("bookingInfo"));
  const [transaction, setTransaction] = useState(null);
  const [errorPaymentHandle, setErrorPaymentHandle] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    handlePayment();
  }, []);

  const handlePayment = async () => {
    setLoading(true);
    const queryParams = new URLSearchParams(location.search);
    const amount = queryParams.get("amount");
    const payment_type = queryParams.get("payment_type");
    const reference_number = queryParams.get("reference_number");
    const status = queryParams.get("status");
    const trans_ref_no = queryParams.get("trans_ref_no");
    const website_id = queryParams.get("website_id");
    const signature = queryParams.get("signature");
    const messageVtc = queryParams.get("message");
    setStatusPayment(status === "1");
    try {
      const body = {
        OrderId: reference_number,
        Amount: Number(amount),
        Message: messageVtc,
        Status: Number(status),
        PaymentType: payment_type,
        ReferenceNumber: reference_number,
        TransRefNo: trans_ref_no,
        WebsiteId: website_id,
        Signature: signature,
      };
      await $axios.$post(
        bookingInfo.isPromotionOrSameDayTicket
          ? "/payment/paymentHandleTicketToDay"
          : "/payment/paymentHandle",
        body
      );
    } catch (error) {
      setErrorPaymentHandle(true);
      notification.error({
        status: "error",
        message: "Thất bại",
        description: "Đã có lỗi xảy ra!",
      });
    } finally {
      if (status === "1") {
        const res = await $axios.$get(
          `/transactions/details?orderCode=${reference_number}`
        );
        setTransaction(res.data.data.data);
      }
      setLoading(false);
      setLoaded(true);
      setTimeout(() => {
        sessionStorage.removeItem("bookingInfo");
        const { pathname } = location;
        history.replace(pathname);
      }, 2000);
    }
  };

  return (
    <div className="bg-[#F6F6F6] h-screen">
      <div>
        <img src={BgSuccess} alt="" className="w-full" />
        <div className="w-full mb-4">
          <div className="flex flex-col justify-center gap-4 w-full px-4 items-center">
            {loaded && (
              <>
                {statusPayment ? (
                  <div>
                    <div className="text-[20px] text-[#00AB56] font-bold text-center mb-4">
                      Thanh toán thành công
                    </div>
                    {!errorPaymentHandle && transaction && (
                      <div className="text-center text-[16px] text-[#27272A]">
                        Chúng tôi vừa gửi cho bạn một email có chứa thông tin
                        đặt vé, xin vui lòng kiểm tra lại email của bạn
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="text-[20px] text-[#D72F20] font-bold text-center">
                    Thanh toán thất bại
                  </div>
                )}
              </>
            )}
            <div className="text-center text-[14px] text-[#27272A] mt-4">
              Tham khảo hàng trăm chuyến bay khác trên IPass ngay bây giờ!
            </div>
          </div>
          <div className="w-full mt-4 px-4">
            <div className="flex justify-between mt-4">
              <Button
                onClick={() => {
                  window.location.href = "/";
                }}
                className="w-[45%] h-[45px] rounded-[10px] text-center mx-auto bg-white text-[#0064D2] border-[#0064D2] text-[15px]"
              >
                <span className="font-semibold">Trang chủ</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex bg-white h-[81px] w-full px-[24px] py-3 fixed bottom-0 justify-between items-center">
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-may-bay.svg"} alt="" />
          <a href="/ve-may-bay" className="text-[#22313F] text-[14px] font-medium">
            Vé máy bay
          </a>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/khach-san.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">Khách sạn</span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-tau.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">Vé tàu</span>
        </div>
        <div className="flex flex-col items-center">
          <img className="w-[28px]" src={"../images/ve-xe.svg"} alt="" />
          <span className="text-[#808080CC] text-[14px] font-medium">Vé xe</span>
        </div>
      </div> */}
    </div>
  );
};

export default Step6;
